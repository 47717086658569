import React from 'react';
import Router from 'next/router';
import Head from 'next/head';
import {
  Layout,
} from 'antd';
import NProgress from 'nprogress';

type LayoutBaseProps = {
  children: any;
  title?: string;
  description?: string;
};

const defaultDescription: string = 'People Search Platform';

export default function LayoutBase({ children, title, description }: LayoutBaseProps) {
  if (Router.events) {
    Router.events.on('routeChangeStart', () => NProgress.start());
    Router.events.on('routeChangeComplete', () => NProgress.done());
    Router.events.on('routeChangeError', () => NProgress.done());
  }

  const htmlTitle = title ? `${title} - Set Origin` : 'Set Origin';

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Head>
        <meta charSet="UTF-8" />
        <title>{htmlTitle}</title>
        <meta name="description" content={description || defaultDescription} />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Layout.Content>
        <style jsx global>
          {`
            html { scroll-behavior: smooth; }
          `}
        </style>
        {children}
      </Layout.Content>
    </Layout>
  );
}
