import React from 'react';
import {
  Row,
  Col,
  Typography,
  Card,
  Button,
  Alert,
} from 'antd';
import LayoutPage from '../components/Layout/Page';

const { Title, Paragraph } = Typography;

export default function Page() {
  return (
    <LayoutPage>
      <Row gutter={[24, 24]} justify="center">
        <Col md={8} style={{ textAlign: 'center' }}>
          <Alert type="success" message="SetOrigin is currently private and invite only" />
          <Title style={{ marginTop: 24 }}>The People Lookup Tool</Title>
          <Paragraph>
            We make it fast and easy to look up millions of people using billions of data points.
          </Paragraph>
        </Col>
      </Row>
      <Row gutter={[24, 24]} justify="center">
        <Col md={8}>
          <Card title="Support">
            <Button block type="primary" href="/charge" style={{ marginBottom: 16 }}>Question About Charge on Card</Button>
            <Button block type="primary" href="/support">General Support</Button>
          </Card>
        </Col>
        <Col md={8}>
          <Card title="About">
            <Paragraph>
              Our main focus is building multiple processes, technologies, and sources to build a large people database.
            </Paragraph>
            <Paragraph>
              This can include data from several different sources, both private and public. We crawl the web, find patterns, source information from vendors, and collect data directly from people. We then combine all this data into a single resource.
            </Paragraph>
            <Paragraph style={{ marginBottom: 0 }}>
              Finally, we make the result of this work available in this simple to use tool. We aim to make this tool more available over time.
            </Paragraph>
          </Card>
        </Col>
      </Row>
    </LayoutPage>
  );
}
