import React from 'react';
import {
  Layout,
} from 'antd';
import LayoutBase from './Base';

type LayoutPageProps = {
  children: any;
  title?: string;
  description?: string;
};

export default function LayoutPage(props: LayoutPageProps) {
  const {
    children,
    title,
    description,
  } = props;

  return (
    <LayoutBase title={title} description={description}>
      <Layout>
        <Layout.Content>
          {children}
        </Layout.Content>
        <Layout.Footer style={{ textAlign: 'center', color: 'rgba(0,0,0,.45)' }}>
          <div>
            <a href="/" style={{ color: 'rgba(0,0,0,.45)', margin: '0 8px' }}>Home</a>
            <a href="/app" style={{ color: 'rgba(0,0,0,.45)', margin: '0 8px' }}>App</a>
            <a href="/terms" style={{ color: 'rgba(0,0,0,.45)', margin: '0 8px' }}>Terms of Use</a>
            <a href="/privacy" style={{ color: 'rgba(0,0,0,.45)', margin: '0 8px' }}>Privacy Policy</a>
          </div>
          <div>Copyright {new Date().getFullYear()} SetOrigin</div>
        </Layout.Footer>
      </Layout>
    </LayoutBase>
  );
}
